import {
  HttpContext,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DialogService } from '../components/dialog/dialog.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private dialogService: DialogService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        if (
          e.status !== 401 &&
          !(e.status === 400 && e.url?.includes('oauth/token'))
        ) {
          this.dialogService.alert(this.getErrorMessages(e), 'error');
        }
        return throwError(() => e);
      })
    );
  }

  private getErrorMessages({ status, error }: HttpErrorResponse): string {
    if (error?.errors) {
      const e: {
        code: string;
        errors: { [key: string]: string }[];
        message: string;
      } = error;
      let message = `${e.message}[${e.code}]`;
      e.errors.forEach((err) => {
        Object.entries(err).forEach(([key, value]) => {
          message += `\n${key} : ${value}`;
        });
      });
      return message;
    }

    if (status === 400) {
      // api 서버에서 badRequest 응답을 받았을 때. 예로 현 golftour 프로젝트에서 ExceptionHandler 없이 error 를 응답하는 경우 등
      const e: {
        content;
      } = error;
      if (e.content) {
        const m = e.content
          .map((err) => `${err.objectName} : ${err.defaultMessage}`)
          .join('\n');
        return `${m}`;
      }
    }

    if (status === 500) {
      const e: {
        status: number;
        error: string;
        message: string;
      } = error;
      return `${e.error}[${e.status}]\n${e.message}`;
    }
    return `Network Error(${status})`;
  }
}
